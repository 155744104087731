<template>
  <div class="space-y-10">
    <!-- pageTitle -->
    <div class="pageTitle">
      <h1>
        Destination:
        <span class="capitalize">{{ $route.params.destination }}</span>
      </h1>
      <h3>Destinations for everyone. Risk free to you.</h3>
    </div>
    <div>
      <RetailPackages :items="items" />
    </div>
  </div>
</template>

<script>
import RetailPackages from "@/components/RetailPackages";
export default {
  components: {
    RetailPackages,
  },
  data: () => ({
    items: [],
    alaska: ["rc7abs"],
    arizona: ["golf528", "golf901"],
    california: [
      "sav527",
      "sav122",
      "sav270",
      "4d204",
      "4d104",
      "sav127",
      "cadmg",
    ],
    colorado: ["sav120", "sav199", "sav219", "4d104"],
    hawaii: [
      "2d200",
      "ipplus",
      "4d100",
      "4d104",
      "fklwm",
      "fowbih",
      "golf904",
      "sav136",
      "sav137",
      "sav236",
      "4d200",
      "4d204",
    ],
    illinois: ["ahg"],
    kansas: ["ahg"],
    louisiana: ["3dmc3"],
    massachusetts: ["husc"],
    missouri: ["sav144", "sav544"],
    montana: ["sav244"],
    nevada: [
      "sav127",
      "3cmlv1",
      "3dclv1",
      "sav227",
      "sav527",
      "4d204",
      "4d104",
    ],
    "new york": ["husc", "mtnyna", "nytsna", "nytswa"],
    "north carolina": ["sav239"],
    pennsylvania: ["husc"],
    tennessee: ["ahg", "3dmc3"],
    texas: ["ahg"],
    virginia: ["4d104", "sav124", "sav524"],
    "washington d.c.": ["husc"],
    alberta: ["cradv"],
    "british columbia": ["sav202"],
    bahamas: ["sav109", "sav509", "4d204"],
    jamaica: ["ai4ic"],
    "dominican republic": ["ai4ic"],
    bermuda: ["ipplus"],
    austria: ["gce4na", "gce4wa", "vwfyna", "vwfywa"],
    "czech republic": ["gce4na", "gce4wa", "cotvna", "cotvwa"],
    england: ["4luk1"],
    france: ["wahpna", "wahpwa"],
    greece: ["rc7gbs"],
    ireland: ["2d200", "ir2bd", "ircmwt", "lotina", "lotiwa"],
    italy: ["gce4na", "gce4wa", "rc7gbs", "wirna", "wirwa"],
    spain: ["4d100", "4d200", "sav211"],
    cancun: ["oshac", "sav118"],
    cozumel: ["ai4ic"],
    "los cabos": ["4d100", "sav104"],
    "puerto vallarta": ["4d200", "sav103"],
    "riviera maya": ["sav118"],
    indonesia: ["4d200"],
    india: ["4d102"],
    argentina: ["4d102"],
    "gold coast": ["4d102"],
  }),
  watch: {
    "$store.state.retailPackages": {
      immediate: true,
      deep: true,
      handler(items) {
        if (!items.length) return;
        if (!this.$route.params.destination) return;
        let destination = this[this.$route.params.destination];
        if (!destination || destination == undefined) return;
        destination.forEach((i) => {
          let packageFound = this.$store.state.retailPackages.find(
            (item) => item.packageCode == i
          );
          if (!packageFound) return;
          this.items.push(packageFound);
        });
      },
    },
    "$route.params.destination": {
      handler(newDestination, oldDestination) {
        if (newDestination && oldDestination) {
          if (newDestination != oldDestination) {
            let destination = this[newDestination];
            if (!destination || destination == undefined) return;
            this.items = [];
            destination.forEach((i) => {
              let packageFound = this.$store.state.retailPackages.find(
                (item) => item.packageCode == i
              );
              if (!packageFound) return;
              this.items.push(packageFound);
            });
          }
        }
      },
    },
  },
};
</script>
